import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Logo from "./components/images/pie3dlogo_black.svg"

const title_image = "/closed.svg"
const background_image = "/bottom.png"

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Header: styled.header`
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
  `,
  MainCover: styled.img`
    width: 100%;
    margin-top: 40px;
  `,
  Message: styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-top: 56px;
  `,

  NextBtn: styled.button`
    width: 333px;
    height: 64px;
    padding: 20px 0;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    border: 0;
    border-radius: 2px;
    background-color: #ff8838;
    margin: 0 auto;
    margin-top: 46px;
    -webkit-box-shadow: 0px 12px 20px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 12px 20px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 12px 20px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  `,
  Footer: styled.footer``,
  FooterImg: styled.img`
    display: block;
    width: 100%;
  `,
}

export default function Main() {
  return (
    <Style.Wrapper>
      <div>
        <Style.Header>
          <img
            src={Logo}
            onClick={() => {
              window.location.href = "https://www.pie3d.com/l/ending-credit"
            }}
            alt={"logo"}
          />
        </Style.Header>
        <Style.MainCover src={title_image} />
        <Style.Message>
          엔딩 크레딧 2022를 사랑해주셔서 감사합니다.
          <br />더 재미있는 프로젝트로 돌아올게요.
          <br />
          파이3D에도 많은 관심 부탁드려요!
        </Style.Message>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Style.NextBtn
            onClick={() => {
              window.location.href = "https://www.pie3d.com/l/ending-credit"
            }}
          >
            파이3D 구경 가기!
          </Style.NextBtn>
        </div>
      </div>
      <Style.FooterImg src={background_image} />
    </Style.Wrapper>
  )
}
