import axios from "axios"

export const api = axios.create({
  baseURL: "https://api.ending-credit.pie3d.com",
  timeout: 5000,
})

api.interceptors.request.use((config) => {
  return config
})
