import { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"
import styled from "styled-components"
import Editor from "./Editor"
import "./App.css"
import DesignSelector from "./DesignSelector"
import Share from "./Share"
import View from "./View"
import MainPage from "./Main"
import CutSizeChooser from "./CutSizeChooser"
const Main = styled.div`
  max-width: 450px;
  margin: 0 auto;
`

const Loading = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  color: white;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
`

const LoadingMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 500;
`

export default function App() {
  const [isLoading, setIsLoading] = useState(false)
  // if inside kakaotalk browser in android
  useEffect(() => {
    // if user agent has both kakaotalk and android
    if (
      navigator.userAgent.match(/Android/i) &&
      navigator.userAgent.match(/KAKAOTALK/i)
    ) {
      window.location.href =
        "intent://" +
        window.location.href.replace(/https?:\/\//i, "") +
        "#Intent;scheme=http;package=com.android.chrome;end"
    }
  }, [])
  return (
    <>
      <Loading
        style={{
          visibility: isLoading ? "visible" : "hidden",
          opacity: isLoading ? 1 : 0,
          zIndex: 999999,
        }}
      >
        <LoadingMessage>Loading...</LoadingMessage>
      </Loading>
      <Main>
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
          {/* <Route
            path="/chooser"
            element={<CutSizeChooser setIsLoading={setIsLoading} />}
          ></Route>
          <Route
            path="/design/:count"
            element={<DesignSelector setIsLoading={setIsLoading} />}
          ></Route>
          <Route
            path="/editor/:id"
            element={<Editor setIsLoading={setIsLoading} />}
          ></Route> */}
          <Route
            path="/view/:id"
            element={<View setIsLoading={setIsLoading} />}
          ></Route>
          <Route
            path="/share/:id"
            element={<Share setIsLoading={setIsLoading} />}
          ></Route>
          <Route path="*" element={<MainPage />}></Route>
        </Routes>
      </Main>
    </>
  )
}
