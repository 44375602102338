import { useEffect, useState, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import PusherJS from "pusher-js"
import styled from "styled-components"
import { api } from "./axios"
import circle_on from "./components/images/circle_on.svg"
import circle_off from "./components/images/circle_off.svg"
import Logo from "./components/images/pie3dlogo_black.svg"

const imageStyle = {
  userDrag: "none",
  WebkitUserDrag: "none",
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
}

const SubmitButton = styled.button`
  width: 85%;
  height: 50px;
  margin: 12px auto;
  display: block;
  padding: 10px 20px;
  background-color: #ff8838;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`
const InviteFriendButton = styled.button`
  width: 85%;
  height: 50px;
  margin: 10px auto;
  display: block;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-radius: 2px;
  border: 1px solid #000;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`
const NameInput = styled.input`
  text-align: left;
  width: 70%;
  height: 100%;
  float: left;
  margin: 0px auto;
  margin-right: calc(3% - 1px);
  display: block;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border: 1px solid #000;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &::placeholder {
    font-weight: 400;
    color: #adadb6;
  }
`
const NameInputContainer = styled.div`
  width: 85%;
  height: 50px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 56px;
  display: block;
`
const NameInputButton = styled.button`
  width: 27%;
  float: left;
  border-radius: 2px;
  height: 100%;
  margin: 0px 0px;
  padding: 10px 20px;
  background-color: #c8c8c8;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`

const ConnectedStatusText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin: 0;
  color: #686870;
`

const WrapperDiv = styled.div`
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 50px;
`

const GoHomeText = styled.p`
  font-size: 14px;
  margin: 0;
  cursor: pointer;
`
const LogoImg = styled.img`
  width: 100px;
  height: 100%;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  margin-left: 0;
  object-fit: contain;
  margin-bottom: 22px;
`
const Img = styled.img``

const CircleImg = styled.img`
  margin-right: 3px;
`

export const loadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.crossOrigin = "anonymous"
    img.src = url
  })
}

const resizeImage = async (image, width, height) => {
  console.log(`resizeImage(${image}, ${width}, ${height})`)
  const canvas = document.createElement("canvas")
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext("2d")
  const scale = Math.max(width / image.width, height / image.height)
  const x = width / 2 - (image.width / 2) * scale
  const y = height / 2 - (image.height / 2) * scale
  ctx.drawImage(image, x, y, image.width * scale + 1, image.height * scale + 1)
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Canvas is empty"))
        return
      }
      resolve(blob)
    }, "image/jpeg")
  })
}

const getMousePos = (canvas, evt) => {
  const rect = canvas.getBoundingClientRect()
  return {
    x: evt.clientX - rect.left,
    y: evt.clientY - rect.top,
    xP: (evt.clientX - rect.left) / rect.width,
    yP: (evt.clientY - rect.top) / rect.height,
    clientX: evt.clientX,
    clientY: evt.clientY,
    rectLeft: rect.left,
    rectTop: rect.top,
  }
}

const onDataChange = async (canvas, data, fontWeight) => {
  const ctx = canvas.getContext("2d")
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  const baseImg = await loadImage(data.baseImage)
  const placeholderImg = await loadImage(data.placeholderImage)
  for (let key in data["subImages"]) {
    if (data["subImages"][key].image) {
      const subImg = await loadImage(data["subImages"][key].image)
      console.log(subImg)
      ctx.drawImage(
        subImg,
        data["subImages"][key].x,
        data["subImages"][key].y,
        data["subImages"][key].width,
        data["subImages"][key].height,
      )
    } else {
      ctx.drawImage(
        placeholderImg,
        data["subImages"][key].x,
        data["subImages"][key].y,
        data["subImages"][key].width,
        data["subImages"][key].height,
      )
    }
  }
  ctx.drawImage(baseImg, 0, 0, canvas.width, canvas.height)
  if (data.name) {
    const font_weight = data.name_font_weight == 400 ? "Regular" : "Medium"
    const font = new FontFace(
      "Pretendard_PIE",
      `url(https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-${font_weight}.woff)`,
    )

    await font.load()
    document.fonts.add(font)
    ctx.font = "24px Pretendard_PIE"
    ctx.fillStyle = data.name_color ? data.name_color : "#ffffff"
    ctx.textAlign = "center"
    ctx.fillText(data.name, canvas.width / 2, data.name_top)
  }
}

const updateClickPositions = async (setClickPositions, data) => {
  const positionList = []
  for (let key in data["subImages"]) {
    positionList.push({
      id: key,
      pctXStart: data["subImages"][key].x / data.width,
      pctXEnd:
        (data["subImages"][key].x + data["subImages"][key].width) / data.width,
      pctYStart: data["subImages"][key].y / data.height,
      pctYEnd:
        (data["subImages"][key].y + data["subImages"][key].height) /
        data.height,
    })
  }
  setClickPositions(positionList)
}

export default function Editor({ setIsLoading }) {
  const { id } = useParams()
  const [connectedUserCnt, setConnectedUserCnt] = useState(0)
  const updateMemberCount = (channel) => {
    console.log(Object.keys(channel.members.members))
    setConnectedUserCnt(Object.keys(channel.members.members).length - 1)
  }
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const [imageDataURL, setimageDataURL] = useState(
    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==",
  )
  const [isOnline, setIsOnline] = useState(false)
  const nameInputRef = useRef(null)
  const [fontWeight, setFontWeight] = useState(500)
  const lastReqId = useRef(null)
  const runSetData = async (newData) => {
    if (newData.name_font_weight) {
      setFontWeight(newData.name_font_weight)
    }
    setIsLoading(true)
    await setData(newData)
    await updateClickPositions(setClickPositions, newData)
    await onDataChange(canvas.current, newData, fontWeight)
    setimageDataURL(canvas.current.toDataURL())
    setIsLoading(false)
  }
  const handleSave = async () => {
    setIsLoading(true)
    for (let key in data["subImages"]) {
      if (!data["subImages"][key].image) {
        setIsLoading(false)
        alert("이미지를 모두 채워주세요.")
        return
      }
    }
    canvas.current.toBlob(async (blob) => {
      const formData = new FormData()
      formData.append("final_image", blob)
      api
        .post(`/api/session/${id}/save`, formData)
        .then((res) => {
          setIsLoading(false)
          console.log(res)
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    })
  }
  const handleSetName = async (e) => {
    const name = nameInputRef.current.value
    setIsLoading(true)
    api.post(`/api/session/${id}/name`, { name: name }).then((res) => {
      console.log(res)
      lastReqId.current = res.data.reqid
      runSetData(res.data.data)
    })
  }
  const handleShare = async () => {
    const copyText = `저와 함께 엔딩크레딧을 완성해봐요!\n${window.location.href}`
    if (navigator.share) {
      navigator
        .share({
          text: copyText,
        })
        .then(() => {
          console.log("Thanks for sharing!")
        })
        .catch(console.error)
    } else {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert("링크가 복사되었습니다.")
        })
        .catch(() => {
          prompt(
            "링크 복사에 실패했습니다.\n링크를 직접 복사해주세요.",
            copyText,
          )
        })
    }
  }
  useEffect(() => {
    let client = new PusherJS("EE7VWS4N3UECV7CRDY7N7E2BR", {
      wsHost: "live.pie3d.dev",
      wssPort: 443,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
      channelAuthorization: {
        endpoint: "https://api.ending-credit.pie3d.com/api/pusher/channel-auth",
        transport: "ajax",
        params: { session_id: id },
        headers: {},
      },
    })

    client.connection.bind("state_change", function (states) {
      if (states.current === "connected") {
        setIsOnline(true)
      } else {
        setIsOnline(false)
      }
      // states = {previous: 'oldState', current: 'newState'}
    })
    const channel = client.subscribe(`presence-${id}`)
    setConnectedUserCnt(Object.keys(channel.members.members).length - 1)
    channel.bind_global(() => {
      updateMemberCount(channel)
    })
    channel.bind("updated", (data) => {
      if (lastReqId.current === data.reqid) {
        return
      }
      lastReqId.current = data.reqid
      runSetData(data.data)
    })
    channel.bind("saved", async (data) => {
      navigate(`/view/${data.id}`)
    })
  }, [id])
  const [clickPositions, setClickPositions] = useState([])
  const canvas = useRef(null)
  const imageRef = useRef(null)
  const inputRef = useRef(null)
  let lastClickPos = useRef(null)
  let subImgWidth = useRef(null)
  let subImgHeight = useRef(null)
  const handleClick = async (evt) => {
    const mousePos = getMousePos(imageRef.current, evt)

    console.log(clickPositions)
    let found = false
    for await (const element of clickPositions) {
      if (
        mousePos.xP >= element.pctXStart &&
        mousePos.xP <= element.pctXEnd &&
        mousePos.yP >= element.pctYStart &&
        mousePos.yP <= element.pctYEnd
      ) {
        lastClickPos.current = element.id
        found = true
      }
    }
    if (!found) {
      lastClickPos.current = null
      return
    }
    subImgHeight.current = data.subImages[lastClickPos.current].height
    subImgWidth.current = data.subImages[lastClickPos.current].width
    await inputRef.current.click()
    // console.log(getMousePos(imageRef.current, evt));
  }
  const handleFileChange = async (e) => {
    if (e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]
    console.log(lastClickPos)
    console.log(data)
    const newImage = await resizeImage(
      await loadImage(URL.createObjectURL(file)),
      subImgWidth.current,
      subImgHeight.current,
    )
    setIsLoading(true)
    const formData = new FormData()
    formData.append("image", newImage)
    formData.append("sub_image", lastClickPos.current)
    formData.append("session_id", id)
    api.post(`api/session/${id}/image`, formData).then((res) => {
      console.log(res)
      lastReqId.current = res.data.reqid
      runSetData(res.data.data)
      e.target.value = null
    })
  }
  useEffect(() => {
    api
      .get(`api/session/${id}`)
      .then((res) => {
        if (res.data.final_image) {
          navigate(`/view/${id}`)
        }
        runSetData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
        window.location.href = "/"
      })
  }, [id])
  return (
    <>
      <WrapperDiv>
        <LogoImg src={Logo} />
        <GoHomeText onClick={() => navigate("/")}>처음으로</GoHomeText>
      </WrapperDiv>
      <Img src="/step3/step3_header.png" style={{ width: "100%" }} />
      <canvas
        ref={canvas}
        width="1000"
        height="1250"
        onClick={handleClick}
        style={{ display: "none" }}
      />
      <img
        src={imageDataURL}
        alt=""
        ref={imageRef}
        onClick={handleClick}
        width={"100%"}
        height={"100%"}
        style={imageStyle}
      />
      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />
      {isOnline ? (
        <ConnectedStatusText>
          <CircleImg src={connectedUserCnt ? circle_on : circle_off} />{" "}
          {connectedUserCnt}명의 다른 사용자와 함께하고 있습니다.
        </ConnectedStatusText>
      ) : (
        <ConnectedStatusText>오프라인</ConnectedStatusText>
      )}
      <NameInputContainer>
        <NameInput
          ref={nameInputRef}
          placeholder="내 이름을 적어주세요!"
        ></NameInput>
        <NameInputButton onClick={handleSetName}>적용</NameInputButton>
      </NameInputContainer>
      <SubmitButton onClick={handleSave}>완성!</SubmitButton>
      <InviteFriendButton onClick={handleShare}>
        친구 초대해서 함께 만들기
      </InviteFriendButton>
      <ConnectedStatusText>
        '친구와 함께 만들기'는 친구와 공동 작업을 할 수 있는 기능입니다.
      </ConnectedStatusText>
      <ConnectedStatusText style={{ marginBottom: "45px" }}>
        링크를 복사해서 친구와 함께 표를 채워 보세요.
      </ConnectedStatusText>
    </>
  )
}
