import { useParams } from "react-router-dom"
import { api } from "./axios"
import styled from "styled-components"
import { useEffect, useState, useRef } from "react"
import { loadImage } from "./Editor"
import share_icon from "./components/images/share_icon.svg"
import kakao_img from "./components/images/kakao.svg"
import twitter_img from "./components/images/twitter.svg"

export default function View(props) {
  const { id } = useParams()
  const [image, setImage] = useState(null)
  const generated_image = useRef(null)
  const [name, setName] = useState("You")
  const [imgUrl, setImgUrl] = useState("")
  useEffect(() => {
    props.setIsLoading(true)
    api
      .get(`https://archive.ending-credit.pie3d.com/2022/${id}.json`)
      .then(async (res) => {
        if (res.data.name) {
          const truncatedName =
            res.data.name.length > 20
              ? res.data.name.substring(0, 17) + "..."
              : res.data.name
          setName(truncatedName)
        }
        if (res.data.final_image) {
          setImgUrl(res.data.final_image)
          const image = await loadImage(res.data.final_image)
          setImage(image.src)
          await setTimeout(() => {
            props.setIsLoading(false)
          }, 500)
        }
      })
      .catch((err) => {
        // window.location.href = "/"
        // console.log(err)
      })
  }, [id])
  const handleDownload = async () => {
    // if kakaotalk or everytime
    if (
      window.navigator.userAgent.indexOf("KAKAOTALK") !== -1 ||
      window.navigator.userAgent.indexOf("everytimeApp") !== -1
    ) {
      alert('이미지를 오래 누른 후 "이미지 저장"을 눌러주세요.')
      return
    }
    const link = document.createElement("a")
    const image_blob = await fetch(image).then((r) => r.blob())
    link.setAttribute("href", URL.createObjectURL(image_blob))
    link.setAttribute("download", "ending-credit-2022.png")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const handleKakaoShare = async () => {
    const Kakao = window.Kakao
    console.log(Kakao)
    Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: "ENDING CREDIT 2022 - PIE3D",
        description:
          "2022년 한 해, 다들 어떠셨나요?\n단 12장의 사진으로 한 해를 요약해 봐요.",
        imageUrl: imgUrl,
        imageWidth: 1000,
        imageHeight: 1250,
        link: {
          mobileWebUrl: `https://${window.location.host}/share/${id}`,
          webUrl: `https://${window.location.host}/share/${id}`,
        },
      },
      buttonTitle: "나만의 Ending Credit 만들기",
    })
  }
  const handlaTwitterShare = async () => {
    const url =
      "https://twitter.com/share?url=" +
      encodeURIComponent(`https://${window.location.host}/share/${id}`)
    const text = encodeURIComponent(
      "ENDING CREDIT 2022\n\n2022년 한 해, 다들 어떠셨나요?\n\n파이3D에서 단 12장의 사진으로 한 해를 요약해 봐요.\n\n🔗 단 몇 번의 클릭으로 쉽게 연말정산 표 만들기!\n#연말정산 #연말정산틀 #엔딩크레딧\n\n",
    )
    window.open(url + "&text=" + text)
  }
  const handleShare = async () => {
    const copyText = `https://${window.location.host}/share/${id}`
    if (navigator.share) {
      navigator
        .share({
          text: copyText,
        })
        .then(() => {
          console.log("Thanks for sharing!")
        })
        .catch(console.error)
    } else {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert("링크가 복사되었습니다.")
        })
        .catch(() => {
          prompt(
            "링크 복사에 실패했습니다.\n링크를 직접 복사해주세요.",
            copyText,
          )
        })
    }
  }
  document.querySelector("body").style.backgroundColor = "#000"
  const Image = styled.img`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: contain;
  `
  const DownloadButton = styled.button`
    width: 85%;
    height: 50px;
    margin: 16px auto;
    display: block;
    margin-top: 60px;
    padding: 10px 20px;
    background-color: #ff8838;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      border: 1px solid #000;
      background-color: #fff;
      font-weight: 500;
      color: #000;
    }
  `
  const NewButton = styled.button`
    width: 85%;
    height: 50px;
    margin: 12px auto;
    display: block;
    padding: 10px 20px;
    background-color: #000000;
    border: 0.7px solid #fff;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  `

  const ShareButton = styled.button`
    width: 85%;
    height: 50px;
    margin: 20px auto;
    margin-top: 42px;
    display: block;
    padding: 10px 20px;
    background-color: #000000;
    border: 0.7px solid #fff;
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 18px 18px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  `

  const CopyrightText = styled.div`
    font-size: 10px;
    color: #fff;
    text-align: center;
    margin-top: 5px;
  `

  const ShareEndingCreditText = styled.div`
    font-size: 18px;
    color: #fff;
    text-align: center;
  `

  const ShareButtonWrapper = styled.div`
    width: 85%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 19px;
  `

  const SNSShareButton = styled.button`
    width: 48%;
    height: 100%;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    border: 0.7px solid #fff;
    background-repeat: no-repeat;
    background-position: 0px center;
    background-size: 50px 50px;
    background-color: #000000;
    padding: 0px 10px 0px 60px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    line-height: 16px;
    &:hover {
      background-color: #fff;
      color: #000;
    }
  `

  return (
    <>
      <div style={{ fontSize: 0 }}>
        <Image src="/result/header_image.png" />
        <Image src={image} ref={generated_image} />
      </div>
      <CopyrightText>Copyright 2022 {name}. All Rights Reserved</CopyrightText>
      <DownloadButton onClick={handleDownload}>
        엔딩 크레딧 다운받기
      </DownloadButton>
      <NewButton
        onClick={() => {
          window.location.href = "/"
        }}
      >
        새로운 엔딩 크레딧 만들러 가기
      </NewButton>
      <ShareEndingCreditText style={{ marginTop: "70px" }}>
        친구에게 엔딩 크레딧을
      </ShareEndingCreditText>
      <ShareEndingCreditText>공유해 주세요!</ShareEndingCreditText>
      <ShareButton
        style={{ backgroundImage: `url(${share_icon})` }}
        onClick={handleShare}
      >
        엔딩 크레딧 공유하기
      </ShareButton>
      <ShareButtonWrapper>
        <SNSShareButton
          style={{ backgroundImage: `url(${kakao_img})` }}
          onClick={handleKakaoShare}
        >
          카카오톡으로
          <br />
          공유하기
        </SNSShareButton>
        <SNSShareButton
          style={{ backgroundImage: `url(${twitter_img})` }}
          onClick={handlaTwitterShare}
        >
          트위터로
          <br />
          공유하기
        </SNSShareButton>
      </ShareButtonWrapper>
      <Image src="/result/footer.png" />
    </>
  )
}
