import { useParams } from "react-router-dom"
import { api } from "./axios"
import styled from "styled-components"
import { useEffect, useState, useRef } from "react"
import { loadImage } from "./Editor"

export default function Share(props) {
  const { id } = useParams()
  const [image, setImage] = useState(null)
  const generated_image = useRef(null)
  const [name, setName] = useState("You")
  useEffect(() => {
    props.setIsLoading(true)
    api
      .get(`https://archive.ending-credit.pie3d.com/2022/${id}.json`)
      .then(async (res) => {
        if (res.data.name) {
          const truncatedName =
            res.data.name.length > 20
              ? res.data.name.substring(0, 17) + "..."
              : res.data.name
          setName(truncatedName)
        }
        if (res.data.final_image) {
          const image = await loadImage(res.data.final_image)
          setImage(image.src)
          await setTimeout(() => {
            props.setIsLoading(false)
          }, 500)
        }
      })
      .catch((err) => {
        window.location.href = "/"
        // console.log(err)
      })
  }, [id])
  document.querySelector("body").style.backgroundColor = "#000"
  const Image = styled.img`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: contain;
  `
  const DownloadButton = styled.button`
    width: 85%;
    height: 50px;
    margin: 0px auto;
    display: block;
    margin-top: 60px;
    padding: 10px 20px;
    background-color: #ff8838;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      border: 1px solid #000;
      background-color: #fff;
      font-weight: 500;
      color: #000;
    }
  `
  const CopyrightText = styled.div`
    font-size: 10px;
    color: #fff;
    text-align: center;
    margin-top: 5px;
  `

  return (
    <>
      <div style={{ fontSize: 0 }}>
        <Image src="/result/header_image.png" />
        <Image src={image} ref={generated_image} />
      </div>
      <CopyrightText>Copyright 2022 {name}. All Rights Reserved</CopyrightText>
      <DownloadButton
        onClick={() => {
          window.location.href = "/"
        }}
      >
        나만의 엔딩 크레딧 만들러 가기
      </DownloadButton>
      <Image src="/result/footer.png" />
    </>
  )
}
