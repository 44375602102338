import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
import { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { api } from "./axios"
import "swiper/css"
import Logo from "./components/images/pie3dlogo_black.svg"
import { useNavigate } from "react-router-dom"
const WrapperDiv = styled.div`
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 50px;
`

const GoHomeText = styled.p`
  font-size: 14px;
  margin: 0;
  cursor: pointer;
`

const images = {
  count_4: {
    white: "14522595-849a-467c-b291-bbafbe7d43ef",
    black: "60cb4d77-0ec6-4b55-93b7-6846b71cf041",
    metal: "d4a5bc43-a2e7-4228-8b3a-ed62778ebf58",
    blingbling: "8af4fcdc-5853-49d8-a860-f0daeafc62b6",
    christmas: "477bd0cb-3cc6-4d03-bdc1-7a98c7cdb6c1",
  },
  count_12: {
    white: "e731eff6-ccdc-4cdd-8f4a-425a4c5d3849",
    black: "242848a3-5ccb-4047-b776-d84fa79255a1",
    metal: "fad8bbf6-5908-4f5c-b442-eae02ab104e8",
    blingbling: "64bdf59d-4dec-4538-8acd-a87ac2d8e075",
    christmas: "bcc49999-c6a2-4045-9b00-21013cdc1a80",
  },
}
export default function DesignSelector(props) {
  const [selectedDesign, setSelectedDesign] = useState(null)
  const { count } = useParams()
  const navigate = useNavigate()
  const handleSelect = (design) => {
    setSelectedDesign(design)
  }
  const selectCompleteHandler = () => {
    if (selectedDesign) {
      const img_id = images[`count_${count}`][selectedDesign]
      props.setIsLoading(true)
      api
        .post("api/session/create", { base_image: img_id })
        .then((res) => {
          const { data } = res
          navigate(`/editor/${data.id}`)
        })
        .catch((err) => {
          console.log(err)
          alert("에러가 발생했습니다. 다시 시도해주세요.")
        })
    } else {
      alert("디자인을 선택해주세요.")
    }
  }
  useEffect(() => {
    props.setIsLoading(true)
    const images = document.querySelectorAll("img")
    let imagesLoaded = 0

    // create a new image object for each image on the page
    const imageObjects = []
    images.forEach((image) => {
      const img = new window.Image()
      img.src = image.src
      imageObjects.push(img)
    })

    // set up a callback to be executed when each image finishes loading
    imageObjects.forEach((img) => {
      img.onload = () => {
        imagesLoaded += 1
        if (imagesLoaded === images.length) {
          // all images have been loaded
          props.setIsLoading(false)
        }
      }
    })
  }, [])

  const SelectButton = styled.button`
    width: 85%;
    height: 50px;
    margin: 0px auto;
    margin-bottom: 37px;
    display: block;
    margin-top: 60px;
    padding: 10px 20px;
    background-color: #ff8838;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      border: 1px solid #000;
      background-color: #fff;
      font-weight: 500;
      color: #000;
    }
  `

  const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `

  return (
    <>
      <WrapperDiv>
        <Img src={Logo} style={{ width: "100px", marginLeft: "0" }} />
        <GoHomeText onClick={() => navigate("/")}>처음으로</GoHomeText>
      </WrapperDiv>
      <Img src="/step2/step2_header.png" />
      <Swiper
        spaceBetween={10}
        slidesPerView={1.3}
        centeredSlides={true}
        loop={true}
        allowTouchMove={true}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        style={{ zIndex: 30 }}
      >
        <SwiperSlide>
          <Img
            src={
              selectedDesign === "black"
                ? `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_black_enabled.png`
                : `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_black_disabled.png`
            }
            onClick={() => handleSelect("black")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Img
            src={
              selectedDesign === "white"
                ? `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_white_enabled.png`
                : `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_white_disabled.png`
            }
            onClick={() => handleSelect("white")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Img
            src={
              selectedDesign === "blingbling"
                ? `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_blingbling_enabled.png`
                : `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_blingbling_disabled.png`
            }
            onClick={() => handleSelect("blingbling")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Img
            src={
              selectedDesign === "metal"
                ? `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_metal_enabled.png`
                : `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_metal_disabled.png`
            }
            onClick={() => handleSelect("metal")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Img
            src={
              selectedDesign === "christmas"
                ? `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_christmas_enabled.png`
                : `https://h2r5d3i2.stackpathcdn.com/step2/${count}cut_christmas_disabled.png`
            }
            onClick={() => handleSelect("christmas")}
          />
        </SwiperSlide>
      </Swiper>
      <SelectButton onClick={selectCompleteHandler}>선택 완료</SelectButton>
    </>
  )
}
