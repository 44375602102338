import styled from "styled-components"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Logo from "./components/images/pie3dlogo_black.svg"
const Img = styled.img`
  width: 85%;
  height: 100%;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  margin-bottom: 22px;
`
const Button = styled.button`
  width: 85%;
  height: 50px;
  margin: 37px auto;
  display: block;
  padding: 10px 20px;
  background-color: #ff8838;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`

const WrapperDiv = styled.div`
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 50px;
`

const GoHomeText = styled.p`
  font-size: 14px;
  margin: 0;
  cursor: pointer;
`

export default function CutSizeChooser(props) {
  const [cutSize, setCutSize] = useState(0)
  const enabled_4cut = "/step1/4cut_enabled.png"
  const disabled_4cut = "/step1/4cut_disabled.png"
  const enabled_12cut = "/step1/12cut_enabled.png"
  const disabled_12cut = "/step1/12cut_disabled.png"
  const router = useNavigate()
  const handleCutSize = (size) => {
    setCutSize(size)
  }
  const handleNextButton = () => {
    props.setIsLoading(true)
    if (cutSize === 4) {
      router("/design/4")
    } else if (cutSize === 12) {
      router("/design/12")
    } else {
      alert("컷수를 선택해주세요.")
      props.setIsLoading(false)
    }
  }

  return (
    <>
      <WrapperDiv>
        <Img src={Logo} style={{ width: "100px", marginLeft: "0" }} />
        <GoHomeText onClick={() => router("/")}>처음으로</GoHomeText>
      </WrapperDiv>
      <Img src="/step1/step1_header.png" style={{ width: "100%" }} />
      <Img
        src={cutSize === 4 ? enabled_4cut : disabled_4cut}
        onClick={() => handleCutSize(4)}
      />
      <Img
        src={cutSize === 12 ? enabled_12cut : disabled_12cut}
        onClick={() => handleCutSize(12)}
      />
      <Button onClick={handleNextButton}>선택 완료</Button>
    </>
  )
}
